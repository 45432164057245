import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store"

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Afspraak maken'
    },
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/inloggen',
    name: 'login',
    meta: {
      title: 'Personeelsomgeving'
    },
    component: () => import('../views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthenticated']) {
        return next({
          name: 'staff.home'
        })
      }

      next()
    }
  },
  {
    path: '/home',
    name: 'staff.home',
    meta: {
      title: 'Homepagina',
      requiresAuth: true
    },
    component: () => import('../views/staff/HomeView.vue')
  },
  {
    path: '/mijn/klanten',
    name: 'staff.myClients',
    meta: {
      title: 'Mijn klanten',
      requiresAuth: true
    },
    component: () => import('../views/staff/MyClientsView.vue')
  },
  {
    path: '/admin/instellingen',
    name: 'staff.admin.settings',
    meta: {
      title: 'Instellingen',
      requiresAuth: true
    },
    component: () => import('../views/staff/admin/settings/SettingsView.vue'),
    children: [
      {
        path: 'personeelsleden',
        name: 'staff.admin.settings.employees',
        meta: {
          title: 'Personeelsleden'
        },
        component: () => import('../views/staff/admin/settings/employees/EmployeesView.vue'),
      },
      {
        path: 'personeelsleden/toevoegen',
        name: 'staff.admin.settings.employees.create',
        meta: {
          title: 'Nieuw personeelslid'
        },
        component: () => import('../views/staff/admin/settings/employees/CreateEmployeeView.vue'),
      },
      {
        path: 'personeelsleden/:id',
        name: 'staff.admin.settings.employees.edit',
        meta: {
          title: 'Bewerk personeelslid'
        },
        component: () => import('../views/staff/admin/settings/employees/EditEmployeeView.vue'),
      },
      {
        path: 'categories',
        name: 'staff.admin.settings.categories',
        meta: {
          title: 'Categorieen'
        },
        component: () => import('../views/staff/admin/settings/categories/CategoriesView.vue'),
      },
      {
        path: 'categories/toevoegen',
        name: 'staff.admin.settings.categories.create',
        meta: {
          title: 'Nieuw categorie'
        },
        component: () => import('../views/staff/admin/settings/categories/CreateCategoryView.vue'),
      },
      {
        path: 'categories/:id',
        name: 'staff.admin.settings.categories.edit',
        meta: {
          title: 'Bewerk categorie'
        },
        component: () => import('../views/staff/admin/settings/categories/EditCategoryView.vue'),
      },
      {
        path: 'diensten',
        name: 'staff.admin.settings.tasks',
        meta: {
          title: 'Diensten'
        },
        component: () => import('../views/staff/admin/settings/tasks/TasksView.vue'),
      },
      {
        path: 'diensten/toevoegen',
        name: 'staff.admin.settings.tasks.create',
        meta: {
          title: 'Nieuw dienst'
        },
        component: () => import('../views/staff/admin/settings/tasks/CreateTaskView.vue'),
      },
      {
        path: 'diensten/:id',
        name: 'staff.admin.settings.tasks.edit',
        meta: {
          title: 'Bewerk diensten'
        },
        component: () => import('../views/staff/admin/settings/tasks/EditTaskView.vue'),
      },
      {
        path: 'vrijstellingen',
        name: 'staff.admin.settings.omissions',
        meta: {
          title: 'Vrijstellingen'
        },
        component: () => import('@/views/staff/admin/settings/ommisions/OmissionsView.vue'),
      },
      {
        path: 'vrijstellingen/toevoegen',
        name: 'staff.admin.settings.omissions.create',
        meta: {
          title: 'Vrijstelling toevoegen'
        },
        component: () => import('@/views/staff/admin/settings/ommisions/CreateOmissionView.vue')
      },
      {
        path: 'blokkades',
        name: 'staff.admin.settings.blockedItems',
        meta: {
          title: 'Blokkades'
        },
        component: () => import('@/views/staff/admin/settings/blockedItems/BlockedItemsView.vue'),
      }
    ]
  },
  {
    path: '/admin/cms/sections',
    name: 'staff.admin.cms.sections',
    meta: {
      title: 'Content secties',
      requiresAuth: true
    },
    component: () => import('../views/staff/admin/cms/ContentSectionsView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = `Damsterbarbershop - ${to.meta.title}`

  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    return {
      path: '/inloggen'
    }
  }
})

export default router
