<template>
  <div class="navigation">
    <ul>
      <li>
        <router-link :to="{name:'staff.home'}">Homepagina</router-link>
      </li>
      <li>
        <router-link :to="{name:'staff.myClients'}">Mijn klanten</router-link>
      </li>
      <li>
        <router-link :to="{name:'staff.admin.settings'}">Instellingen</router-link>
      </li>
      <li>
        <router-link :to="{name:'staff.admin.cms.sections'}">Content beheer</router-link>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "NavigationComponent",
}
</script>

<style lang="scss" scoped>
.navigation {
  ul {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
    list-style: none;

    a {
      border-radius: 18px;
      background-color: #FFFFFF;
      padding: 1rem 2rem;
      box-shadow: var(--default-shadow);
      border: 1px solid var(--divider-color);
      text-decoration: none;
      color: #000000;
      transition: 300ms background-color linear;

      &:hover {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }

    .router-link-active {
      background-color: var(--primary-color);
      color: #ffffff;
    }
  }
}
</style>