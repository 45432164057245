import axios from "axios";

export default new class AuthService {
    login(email, password) {
        return axios.post('api/v1/auth/login', {
            email: email,
            password: password
        })
    }

    profile()
    {
        return axios.get('api/v1/auth/profile')
    }
}