import EmployeeService from "@/services/EmployeeService";

export default {
    namespaced: true,
    state: {
        employees: [],
        employeesWithAppointments: []
    },
    getters: {
        getEmployees(state)
        {
            return state.employees
        },
        getAmountOfEmployees(state)
        {
            return state.employees.length
        }
    },
    mutations: {
        setEmployees(state, employees)
        {
            state.employees = employees
        }
    },
    actions: {
        getEmployees({commit}) {
            EmployeeService.getAll().then((response) => {
                commit('setEmployees', response.data)
            })
        },
    }
}