import axios from "axios";

export default new class InfoService {
    getOpeningHours() {
        return axios.get('/api/v1/info/opening')
    }

    getEmployees() {
        return axios.get('/api/v1/info/employees')
    }

    getTasks() {
        return axios.get('/api/v1/info/tasks')
    }

    getCategories() {
        return axios.get('/api/v1/info/categories')
    }

    getAvailableDates(userId, tasks) {
        const taskIdsString = Array.from(tasks).map(task => task.id).join(',')

        return axios.get(`/api/v1/info/appointments?userId=${userId}&desiredTasks=${taskIdsString}`)
    }

    getAvailableTimeSlots(userId, tasks, date) {
        const taskIdsString = Array.from(tasks).map(task => task.id).join(',')

        return axios.get(`/api/v1/info/timeslots?userId=${userId}&desiredTasks=${taskIdsString}&desiredDate=${date.value}`)
    }
}