<template>
  <div class="alerts">
    <CustomAlertComponent v-for="alert in alerts" :key="alert.id" :type="alert.type" :title="alert.title" :description="alert.description" @click.prevent="closeAlert(alert.id)" />
  </div>
</template>

<script>
import CustomAlertComponent from "@/components/alerts/CustomAlertComponent";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "AlertsComponent",
  components: {CustomAlertComponent},
  data() {
    return {
      alerts: []
    }
  },
  methods: {
    loadAlert(type, title, description, delay) {
      let alert = {
        id: uuidv4(),
        type: type,
        title: title,
        description: description
      }
      this.alerts.push(alert)
      setTimeout(() => {
        let index = this.alerts.findIndex(foundAlert => foundAlert.id === alert.id)
        this.alerts.splice(index, 1)
      }, delay);
    },
    closeAlert(index) {
      this.alerts.splice(index, 1)
    }
  },
  mounted() {
    this.$root.$data.emitter.on('alerts', (alert) => {
      this.loadAlert(alert.type, alert.title, alert.description, alert.delay)
    })
  }
}
</script>

<style lang="scss" scoped>
.alerts {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 350px;
  z-index: 999;
}
</style>