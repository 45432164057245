<template>
  <div class="loading">
    <img src="@/assets/img/logo.jpg" alt="loading" class="logo">
  </div>
</template>

<script>
export default {
  name: "LoadingComponent"
}
</script>

<style lang="scss" scoped>
@keyframes pop {
  55% { transform: translate(-50%, -150%) rotate(15deg); }
  60% { transform: translate(-50%, -150%) rotate(-15deg); }
  65% { transform: translate(-50%, -150%) rotate(15deg); }
  70% { transform: translate(-50%, -150%) rotate(-15deg); }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pop 2s infinite;
}

.logo {
  display: block;
  height: 80px;
  width: 80px;
}
</style>