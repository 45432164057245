import InfoService from "@/services/InfoService";

export default {
    namespaced: true,
    state: {
        openingHours: []
    },
    getters: {
        getOpeningHours(state)
        {
            return state.openingHours
        }
    },
    mutations: {
        setOpeningHours(state, openingHours)
        {
            state.openingHours = openingHours
        }
    },
    actions: {
        getOpeningHours({commit}) {
            InfoService.getOpeningHours().then((response) => {
                commit('setOpeningHours', response.data)
            })
        }
    }
}