<template>
  <div class="alert" :class="type">
    <h2>{{ title }}</h2>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "CustomAlertComponent",
  props: ["type", "title", "description"]
}
</script>

<style lang="scss" scoped>
.alert {
  padding: .75rem;
  margin-bottom: .75rem;
  border: 1px solid var(--divider-color);
  cursor: pointer;

  h2 {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }
}
</style>