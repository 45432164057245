import BlockService from "@/services/BlockService";

export default {
    namespaced: true,
    state: {
        blockedItems: [],
    },
    getters: {
        getBlockedItems(state)
        {
            return state.blockedItems
        },
        getAmountOfBlockedItems(state)
        {
            return state.blockedItems.length
        }
    },
    mutations: {
        setBlockedItems(state, blockedItems)
        {
            state.blockedItems = blockedItems
        }
    },
    actions: {
        getBlockedItems({commit}) {
            BlockService.getAll().then((response) => {
                commit('setBlockedItems', response.data)
            })
        }
    }
}