<template>
  <LoadingComponent v-if="loading" />
  <template v-else-if="status">
    <AlertsComponent />
    <div id="client" v-if="routeName === 'home'">
        <router-view />
    </div>
    <div id="staff" v-else-if="isAuthenticated">
      <HeaderComponent />
      <div class="content">
        <router-view />
      </div>
    </div>
    <div id="auth" v-else-if="routeName === 'login'">
      <router-view />
    </div>
  </template>
  <MaintenanceView v-else />
</template>

<script>
import mitt from 'mitt'
import {mapActions, mapGetters} from "vuex";
import HealthcheckService from "@/services/HealthcheckService";
import AlertsComponent from "@/components/alerts/AlertsComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import MaintenanceView from "@/views/MaintenanceView.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  components: {LoadingComponent, MaintenanceView, HeaderComponent, AlertsComponent},
  data() {
    return {
      emitter: mitt(),
      status: false,
      loading: true
    }
  },
  async created() {
    await this.performTest()
    setInterval(this.performTest, 10000)
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    }),
    routeName() {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions({
      refreshUser: 'auth/refreshUser',
      signOut: 'auth/signOut'
    }),
    async performTest() {
      await HealthcheckService.getStatus().then((response) => {
        this.loading = false
        this.status = response.data.status === "UP"
      }).catch(() => {
        this.loading = false
        this.status = false
      })
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400&display=swap');
@import "assets/utilities.scss";

:root {
  --default-font-family: 'Poppins', sans-serif;
  --default-font-size: .9rem;

  --background-color: #EDF1FF;
  --background-hover: #f2f5f8;
  --background-hover-dark: #3a3b3d;

  --primary-color: #101823;
  --primary-color-2: rgba(190, 167, 144, 0.81);
  --primary-color-3: #614c45;
  --primary-color-4: #062c19;
  --success-color: #047703;
  --danger-color: #AE1E04;
  --warning-color: #AE8F04;

  --navigation-color: #FFFFFF;
  --navigation-color-dark: #242527;
  --divider-color: #e9ebed;

  --twitter-color: #1da1f2;
  --youtube-color: #ff0000;
  --instagram-color: #8a3ab9;
  --snapchat-color: rgba(232, 229, 17, 0.96);
  --linkedin-color: #0072b1;

  --default-shadow: 0px 12px 50px rgb(125 125 125 / 10%);
  --md-shadow: 0px 12px 50px rgb(125 125 125 / 10%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--default-font-family);
}

h1 {
  font-size: 2.9rem;
}

h2 {
  font-size: 1.9rem;
}

p, li, td, label, input[type="text"] {
  font-size: var(--default-font-size)
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

#auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(rgba(0,0,0,0.527),rgba(0,0,0,0.5)), url("@/assets/img/welcome.jpeg");
  -webkit-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#staff {
  background-color:  #f1efee;
  padding-bottom: 1.5rem;
  height: 100vh;
  overflow-y: auto;

  .card-header {
    margin-bottom: 0;
  }
}

h1 {
  font-size: 1.3rem;
}

h2 {
  font-size: 1.2rem;
}
</style>