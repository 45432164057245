<template>
  <div class="profile">
    <ul>
      <li>
        <span class="badge badge-primary">{{ currentTime }}</span>
      </li>
      <li>
        <span @click.prevent="logout">Afmelden</span>
        <div class="avatar-wrapper-sm">
          <img v-if="user.avatar" :src="'data:' + user.avatar_type + ';base64,' + user.avatar" alt="Avatar" class="avatar avatar-sm">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProfileComponent",
  data() {
    return {
      currentTime: this.getCurrentTime()
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut'
    }),
    logout() {
      this.signOut()
      this.$router.push({ name: 'login' })
    },
    getCurrentTime() {
      const now = new Date();
      return now.toLocaleString('nl-NL', { hour12: false });
    },
    updateTime() {
      this.currentTime = this.getCurrentTime();
    }
  },
  mounted() {
    this.interval = setInterval(this.updateTime, 1000);
  },
  unmounted() {
    clearInterval(this.interval);
  }
}
</script>

<style lang="scss" scoped>
.profile {
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    column-gap: 3rem;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;

      &:last-child span {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>