import axios from "axios";

export default new class TaskService {
    getAll() {
        return axios.get('/api/v1/tasks?filter=all')
    }

    getById(id) {
        return axios.get(`/api/v1/tasks/${id}`)
    }

    updateById(id, categoryId, name, priceInCents, durationInMinutes, isActive) {
        return axios.put(`/api/v1/tasks/${id}`, {
            name: name,
            price_in_cents: priceInCents,
            duration_in_minutes: durationInMinutes,
            is_active: isActive,
            category_id: categoryId
        })
    }

    deleteById(id) {
        return axios.delete(`/api/v1/tasks/${id}`)
    }
}