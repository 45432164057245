import AuthService from "@/services/AuthService";

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
    },
    getters: {
        isAuthenticated(state)
        {
            return state.token !== null && state.user !== null
        },
        getUser(state)
        {
            return state.user
        },
        getUserAvatar(state)
        {
            return state.user.avatar
        }
    },
    mutations: {
        setToken(state, token)
        {
            state.token = token
        },
        setUser(state, user)
        {
            state.user = user
        }
    },
    actions: {
        async authenticateUser({dispatch}, credentials)
        {
            const response = await AuthService.login(credentials.email, credentials.password)

            return dispatch('attempt', { token: response.data.token })
        },
        async attempt({commit, state}, object)
        {
            if (object.token) {
                commit('setToken', object.token)
            }

            if (!state.token) {
                return
            }

            return new Promise(((resolve, reject) => {
                AuthService.profile()
                    .then(response => {
                        commit('setUser', response.data)
                        resolve(response)
                    }).catch((error) => {
                        commit('setUser', null)
                        commit('setToken', null)
                        reject(error)
                    }
                )
            }))
        },
        async refreshUser({dispatch})
        {
            return new Promise(((resolve, reject) => {
                dispatch('attempt', { token: localStorage.getItem('token') })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            }))
        },
        signOut({commit}) {
            commit('setToken', null)
            commit('setUser', null)
        },
    }
}