import TaskService from "@/services/TaskService";

export default {
    namespaced: true,
    state: {
        tasks: [],
    },
    getters: {
        getTasks(state)
        {
            return state.tasks
        },
        getAmountOfTasks(state)
        {
            return state.tasks.length
        }
    },
    mutations: {
        setTasks(state, tasks)
        {
            state.tasks = tasks
        }
    },
    actions: {
        getTasks({commit}) {
            TaskService.getAll().then((response) => {
                commit('setTasks', response.data)
            })
        }
    }
}