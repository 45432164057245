import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './store/subscriber'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowDown, faArrowLeft,
    faArrowRight, faArrowUp, faBan,
    faBars, faCalendar, faCalendarDay,
    faEnvelope,
    faEye,
    faHome, faMinus,
    faMoon,
    faPhone,
    faScissors,
    faSnowflake,
    faSpinner,
    faSun,
    faTrash,
    faUsers, faX
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from "axios";

library.add(faCalendarDay, faCalendar, faArrowDown, faArrowUp, faArrowLeft, faX, faMinus, faBan, faArrowRight, faUsers, faBars, faScissors, faTrash, faEye, faPhone, faEnvelope, faHome, faSun, faMoon, faSpinner, faSnowflake)

axios.defaults.baseURL = process.env.VUE_APP_API_PATH

axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 403 && router.currentRoute.value.name !== 'login') {
            store.dispatch('auth/signOut').then(() => location.reload())
        }
        return Promise.reject(error);
    }
);

store.dispatch('auth/attempt', { 'token': localStorage.getItem('token') })
    .then(() => {
        createApp(App)
            .use(store)
            .use(router)
            .component('font-awesome-icon', FontAwesomeIcon)
            .mount('#app')
    })
