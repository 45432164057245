import OmissionService from "@/services/OmissionService";

export default {
    namespaced: true,
    state: {
        omissions: [],
    },
    getters: {
        getOmissions(state)
        {
            return state.omissions
        },
        getAmountOfOmissions(state)
        {
            return state.omissions.length
        }
    },
    mutations: {
        setOmissions(state, omissions)
        {
            state.omissions = omissions
        }
    },
    actions: {
        getOmissions({commit}) {
            OmissionService.getAll().then((response) => {
                commit('setOmissions', response.data)
            })
        }
    }
}