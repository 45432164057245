import axios from "axios";

export default new class OmissionService {
    getAll() {
        return axios.get('/api/v1/omissions')
    }

    getById(id) {
        return axios.get(`/api/v1/omissions/${id}`)
    }

    create(date, isOpen, openingTime, closingTime, users) {
        return axios.post('/api/v1/omissions', {
            date: date,
            is_open: isOpen,
            opening_time: openingTime,
            closing_time: closingTime,
            users: users
        })
    }

    deleteById(id) {
        return axios.delete(`/api/v1/omissions/${id}`)
    }
}