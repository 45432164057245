import axios from "axios";

export default new class BlockService {
    getAll() {
        return axios.get('/api/v1/blocks')
    }

    block(type, value) {
        return axios.post('/api/v1/blocks', {
            type: type,
            value: value
        })
    }

    revoke(id) {
        return axios.delete(`/api/v1/blocks/${id}`)
    }

    isBlocked(type, value) {
        return axios.get(`/api/v1/blocks/check?type=${type}&value=${value}`)
    }
}