import axios from "axios";

export default new class EmployeeService {
    getAll() {
        return axios.get('/api/v1/employees')
    }

    getById(id) {
        return axios.get(`/api/v1/employees/${id}`)
    }

    getByIdWithAppointments(id, date) {
        return axios.get(`/api/v1/employees/${id}/appointments?date=${date}`)
    }

    create(firstName, lastName, email, password, businessHours) {
        return axios.post('/api/v1/employees', {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            business_hours: businessHours,
        })
    }

    uploadAvatar(userId, file) {
        const formData = new FormData();
        formData.append('image', file);

        return axios.post(`/api/v1/employees/${userId}/avatar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    updateById(id, firstName, lastName, password, businessHours) {
        return axios.put(`/api/v1/employees/${id}`, {
            first_name: firstName,
            last_name: lastName,
            password: password,
            business_hours: businessHours
        })
    }

    deleteById(id) {
        return axios.delete(`/api/v1/employees/${id}`)
    }
}