<template>
  <div class="header">
    <div class="logo">
      <h2>Damsterbarbershop</h2>
    </div>
    <NavigationComponent />
    <ProfileComponent />
  </div>
</template>

<script>

import NavigationComponent from "@/components/NavigationComponent.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";

export default {
  name: "HeaderComponent",
  components: {NavigationComponent, ProfileComponent},
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1.5rem;
  background-color: #ffffff;

  .logo {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
  }
}
</style>