import TaskCategoryService from "@/services/TaskCategoryService";

export default {
    namespaced: true,
    state: {
        categories: [],
    },
    getters: {
        getCategories(state)
        {
            return state.categories
        },
        getAmountOfCategories(state)
        {
            return state.categories.length
        }
    },
    mutations: {
        setCategories(state, categories)
        {
            state.categories = categories
        }
    },
    actions: {
        getCategories({commit}) {
            TaskCategoryService.getAll().then((response) => {
                commit('setCategories', response.data)
            })
        }
    }
}