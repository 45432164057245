import axios from "axios";

export default new class TaskCategory {
    getAll() {
        return axios.get('/api/v1/categories?filter=all')
    }

    getActive() {
        return axios.get('/api/v1/categories?filter=active')
    }

    getById(id) {
        return axios.get(`/api/v1/categories/${id}`)
    }

    createCategory(name, position, isActive) {
        return axios.post('/api/v1/categories', {
            name: name,
            position: position,
            is_active: isActive
        })
    }

    createTask(categoryId, name, priceInCents, durationInMinutes, isActive) {
        return axios.post(`/api/v1/categories/${categoryId}/tasks`, {
            name: name,
            price_in_cents: priceInCents,
            duration_in_minutes: durationInMinutes,
            is_active: isActive
        })
    }

    updateById(id, name, position, isActive) {
        return axios.put(`/api/v1/categories/${id}`, {
            name: name,
            position: position,
            is_active: isActive
        })
    }

    deleteById(id) {
        return axios.delete(`/api/v1/categories/${id}`)
    }
}