import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import employees from "@/store/modules/employees";
import info from "@/store/modules/info";
import categories from "@/store/modules/categories";
import tasks from "@/store/modules/tasks";
import omissions from "@/store/modules/omissions";
import blockItems from "@/store/modules/blockedItems";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth, employees, info, categories, tasks, omissions, blockItems
  }
})
